import { PrimaryKeyId } from "./brands.model";

export enum PhotoState {
  current,
  newPhoto,
  markedForDeletion,
}

export type IComment = {
  cloudId: PrimaryKeyId;
  comment: string;
  userId: number;
  username: string;
  created: number;
  lastModified: number;
  photos: ICommentPhoto[];
};

export type ICommentPhoto = {
  cloudId?: PrimaryKeyId;
  base64?: string;
  filename: string;
  fullPath: string;
  thumbnail: string;
  state: PhotoState;
};

import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { TokenGuard } from "./guards/token.guard";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
    canActivate: [TokenGuard],
  },
  {
    path: "projects",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "project/:projectid",
    loadChildren: () =>
      import("./project/project-scaffold.module").then(
        (m) => m.ProjectScaffoldPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "client",
    loadChildren: () =>
      import("./client-home/pages/client-home/client-home.module").then(
        (m) => m.ClientHomePageModule
      ),
  },
  {
    path: "options",
    loadChildren: () =>
      import("./options/options.module").then((m) => m.OptionsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "confirmation/:referenceId/:productId/:quantity",
    loadChildren: () =>
      import("./confirmation/confirmation.module").then(
        (m) => m.ConfirmationPageModule
      ),
  },
  {
    path: "billing/:productId/:quantity",
    loadChildren: () =>
      import("./checkout/checkout.module").then((m) => m.CheckoutPageModule),
  },
  {
    path: "web-login/:redirectTo",
    loadChildren: () =>
      import("./web-login/web-login.module").then((m) => m.WebLoginPageModule),
  },
  {
    path: "install",
    loadChildren: () =>
      import("./get-app/get-app.module").then((m) => m.GetAppPageModule),
  },
  // ** goes last
  { path: "**", redirectTo: "login" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

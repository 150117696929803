import { Injectable } from "@angular/core";

import type {
  IProjectView,
  IHttpProject,
  ProjectPrimaryKeyId,
} from "../models";

import { UserService } from "./user.service";
import { ApiService } from "./api/index";
import { CatchErrorService } from "./catch.service";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  /** @ignore */
  constructor(
    private us: UserService,
    private api: ApiService,
    private cx: CatchErrorService
  ) {}

  /**
   * Get data for displaying on home page
   *
   * @returns device projects and cloud project arrays
   */
  async getHomePageData(): Promise<{ projects: IProjectView[] }> {
    if (!environment.production) {
      console.log("getHomePageData()");
    }
    let cloudProjectsList: IProjectView[] = [];

    try {
      const { cloudProjects } = await this.getHomePageCloudData();
      cloudProjectsList = cloudProjects;
    } catch (ex) {
      await this.cx.handle(ex, true, "Unable to retrieve server projects.");
    }

    cloudProjectsList = cloudProjectsList.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    return Promise.resolve({ projects: cloudProjectsList });
  }

  /**
   * Augments this.projects on home page with cloud project data
   * @param localProjects local sql project data
   * @returns augmented this.projects
   *
   * @since 1.3.0
   */
  async getHomePageCloudData(): Promise<{ cloudProjects: IProjectView[] }> {
    if (!environment.production) {
      console.log("getHomeCloudData()");
    }

    let cloudProjects: IProjectView[] = [];

    const isPremium = await this.us.awaitPremium();

    if (!isPremium) {
      return Promise.resolve({ cloudProjects });
    } else {
      try {
        const httpGet = await this.api.projects.getProjects();

        if (!httpGet.success) {
          return Promise.reject(httpGet.message);
        }

        cloudProjects = [
          ...httpGet.data.map<IProjectView>((o: IHttpProject) => {
            const modDate = new Date(o.lastModified);

            const project: IProjectView = {
              id: o.id as ProjectPrimaryKeyId,
              type: o.type,
              name: o.name,
              reference: o.reference,
              created: o.createDate,
              lastProjectSyncDate: o.lastModified,
              lastModified: o.lastModified,
              lastModifiedDate: modDate,
            };
            return project;
          }),
        ];
      } catch (ex) {
        return Promise.reject(ex);
      }
    }

    return Promise.resolve({ cloudProjects });
  }
}

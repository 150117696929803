import { Injectable } from "@angular/core";

import { ApiService } from "./api/index";

import type {
  IHttpUsers,
  IHttpTeamUser,
  IApiResponse,
  TenantRole,
  ISendInvite,
  ITenantInvite,
  IUserInviteStatus,
} from "../models";
import { PrimaryKeyId, ProjectUserType } from "../models";

import { TenantApiService } from "./api/tenant-api.service";

@Injectable({
  providedIn: "root",
})
export class TeamService {
  /** @ignore */
  constructor(private api: ApiService, private tenantApi: TenantApiService) {}

  /**
   * Loads active tenant users
   * @returns {IHttpUsers}
   */
  async getUsers(): Promise<IHttpUsers> {
    try {
      const result = await this.api.user.getUsers();
      return Promise.resolve(result.data);
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   *
   * @returns {ITenantInvite[]}
   */
  async getInvites(): Promise<ITenantInvite[]> {
    try {
      const result = await this.api.invite.getInvites();
      return result.data;
    } catch (ex) {
      if (ex && JSON.stringify(ex).includes("#200")) {
        return [];
      }
      return Promise.reject(ex);
    }
  }

  /**
   * Send multiple invites or resend one or more invites
   *
   * @param {ISendInvite[]} invites
   * @returns {ITenantInvite[]}
   */
  async sendInvite(
    invites: ISendInvite[]
  ): Promise<IApiResponse<ITenantInvite[]>> {
    try {
      const result = await this.api.invite.sendInvites(invites);
      let inviteResult: ITenantInvite[] = [];
      if (result.success) {
        inviteResult = result.data.map((o) => {
          const date = new Date(o.lastModified);
          return {
            id: o.cloudId,
            firstname: "",
            lastname: "",
            emailAddress: o.emailAddress,
            status: o.inviteStatus,
            createDate: date.getTime(),
          };
        });
      }
      return Promise.resolve({
        success: result.success,
        message: result.message,
        data: inviteResult,
      });
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   * Delete an invite
   *
   * @param {PrimaryKeyId} inviteId
   * @returns {IApiResponse}
   */
  async cancelInvite(inviteId: PrimaryKeyId): Promise<IApiResponse> {
    try {
      const result = await this.api.invite.deleteInvite(inviteId);
      return Promise.resolve(result);
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   *
   * @param user
   * @returns {IApiResponse}
   */
  async setUser(user: IHttpTeamUser): Promise<IApiResponse> {
    try {
      const result = await this.api.user.setUser({
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        position: user.position,
        // TODO: This is defined in ICreateUser which is extended but maybe not needed anymore? SetUser doesn't need it
        company: null,
        emails: null,
        sms: null,
        notifications: null,
      });
      return Promise.resolve(result);
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   * Deletes a user from a tenant team
   * @param {PrimaryKeyId} userId
   * @returns {Promise<boolean>}
   */
  async deleteUser(userId: PrimaryKeyId): Promise<boolean> {
    const result = await this.tenantApi.deleteTenantUser(userId);
    return result.success;
  }

  /**
   * Updates the role of a user for a tenant
   * @param {PrimaryKeyId} userId
   * @param {string} role
   * @returns TBC
   */
  async editUserRole(userId: PrimaryKeyId, role: TenantRole): Promise<boolean> {
    try {
      // tenant role type.
      const entityType = ProjectUserType.user;
      const result = await this.api.user.editTenantRole(
        userId,
        role,
        entityType
      );
      return result.success;
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   *
   * @param {PrimaryKeyId} userId
   * @param {boolean} archived
   * @returns success result
   */
  async editUserStatus(
    userId: PrimaryKeyId,
    archived: boolean
  ): Promise<boolean> {
    try {
      const result = await this.api.user.editUserStatus(userId, archived);
      return result.success;
    } catch (ex) {
      return Promise.reject(ex);
    }
  }
}

import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";

import type {
  IApiResponse,
  IHttpApiUpdateResponse,
  Endpoint,
  IHttpGetSite,
  IApiDeleteResponse,
  ProjectPrimaryKeyId,
  ListPrimaryKeyId,
  List,
  DefectPrimaryKeyId,
  IApiCloneListResponse,
  ClientListResponse,
} from "../../models";
import { ICreateSite, IEditSite } from "../../models";

import { UserService } from "../user.service";
import { HTTP_METHOD } from ".";

@Injectable({
  providedIn: "root",
})
export class ListApiService {
  private _getSite = "GetSite" as Endpoint;
  private _createSite = "CreateSite" as Endpoint;
  private _editSite = "EditSite" as Endpoint;
  private _deleteSite = "DeleteSite" as Endpoint;
  private _editDefects = "EditDefects" as Endpoint;
  private _cloneList = "CloneList" as Endpoint;
  private _clientList = "ClientList" as Endpoint;

  /** @ignore */
  constructor(private http: HttpService, private us: UserService) {}

  /**
   * Returns the site details and defects for a site
   * @param listId Cloud Id of site to get
   * @param projectCloudId
   * @returns {Promise<IApiResponse<IHttpGetSite>>}
   */
  async getSite(
    listId: ListPrimaryKeyId,
    projectCloudId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpGetSite>> {
    const ids = await this.us.checkAndGetUserIds();
    return this.http.request<IHttpGetSite>(
      HTTP_METHOD.GET,
      this._getSite,
      {
        tenantId: ids.tenantId,
        userId: ids.userId,
        siteId: listId,
        projectId: projectCloudId,
      },
      null,
      false
    );
  }

  /**
   * Saves a new site to the cloud database
   * @param {ICreateSite} site Site details to save
   * @param projectId
   * @returns {Promise<IApiResponse<IHttpApiUpdateResponse>>} New cloud Id and lastmodified
   */
  async createSite(
    site: ICreateSite,
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpApiUpdateResponse>> {
    return this.http.request<IHttpApiUpdateResponse>(
      HTTP_METHOD.POST,
      this._createSite,
      null,
      { site, projectId },
      false
    );
  }

  /**
   * Updates the details of an existing site
   * @param {IEditSite} site Updated site data to send
   * @param projectId
   * @returns {Promise<IApiResponse<IHttpApiUpdateResponse>>} Cloud Id and updated lastmodified
   */
  async editSite(
    site: IEditSite,
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpApiUpdateResponse>> {
    return this.http.request<IHttpApiUpdateResponse>(
      HTTP_METHOD.PUT,
      this._editSite,
      null,
      { site, projectId },
      false
    );
  }

  /**
   * Deletes a site and associated defects from cloud database
   * @param {number} listId Cloud Id of the site to delete
   * @param projectId
   * @returns {Promise<IApiResponse>} Success and message
   */
  async deleteSite(
    listId: ListPrimaryKeyId,
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IApiDeleteResponse>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IApiDeleteResponse>(
      HTTP_METHOD.DELETE,
      this._deleteSite,
      null,
      { tenantId, siteId: listId, projectId },
      false
    );
  }

  /**
   * Deletes a site and associated defects from cloud database
   * @param {number} listId Cloud Id of the site to delete
   * @param projectId
   * @returns {Promise<IApiResponse>} Success and message
   */
  async cloneList(
    listId: ListPrimaryKeyId
  ): Promise<IApiResponse<IApiCloneListResponse>> {
    // FIXME This is skipping the validation step, does not correspond w/ app
    return this.http.request<IApiCloneListResponse>(
      HTTP_METHOD.POST,
      this._cloneList,
      null,
      { listId: listId },
      true
    );
  }

  /**
   * Updates the details of an existing site
   * @param {List.DefectMultiEdit} data Updated site data to send
   * @param {DefectPrimaryKeyId[]} defectIds
   * @param projectId
   * @returns {Promise<IApiResponse<IHttpApiUpdateResponse>>} Cloud Id and updated lastmodified
   *
   * @since W2.2.0
   */
  async bulkEditDefects(
    data: List.DefectMultiEdit,
    defectIds: DefectPrimaryKeyId[]
  ): Promise<IApiResponse<IHttpApiUpdateResponse>> {
    return this.http.request<IHttpApiUpdateResponse>(
      HTTP_METHOD.PUT,
      this._editDefects,
      null,
      { ...data, defectIds },
      false
    );
  }

  /**
   * Deletes a site and associated defects from cloud database
   * @param {number} listId Cloud Id of the site to delete
   * @param projectId
   * @returns {Promise<IApiResponse>} Success and message
   *
   * @since W2.5.0
   */
  async clientList(
    listId: ListPrimaryKeyId,
    adds: string[],
    removes: number[]
  ): Promise<IApiResponse<ClientListResponse[]>> {
    return this.http.request<ClientListResponse[]>(
      HTTP_METHOD.POST,
      this._clientList,
      null,
      { listId: listId, adds: adds, removes: removes },
      true
    );
  }
}

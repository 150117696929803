import { Injectable } from "@angular/core";

import { StorageKey } from "../models";

import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  constructor(private store: StorageService) {}

  /**
   * Get a setting from local storage
   * @param setting
   */
  async get(key: StorageKey): Promise<string> {
    const setting = await this.store.getString(key);
    const result = setting == null ? "" : setting;
    return result;
  }

  /**
   * Set a setting from local storage
   * @param setting
   */
  async set(key: StorageKey, value: string): Promise<void> {
    await this.store.set(key, value);
  }
}

import { Injectable } from "@angular/core";
import { Preferences } from "@capacitor/preferences";

import { StorageKey } from "../models";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor() {}

  async set(key: StorageKey, value: any): Promise<void> {
    await Preferences.set({
      key: key,
      value: `${value}`,
    });
  }

  /**
   *
   * @param key
   * @returns
   */
  async getString(key: StorageKey): Promise<string | null> {
    var result = await Preferences.get({ key });
    return result.value;
  }

  /**
   * Returns value parsed as number or null
   * @param key
   * @returns
   */
  async getNumber(key: StorageKey): Promise<number | null> {
    const { value } = await Preferences.get({ key });
    if (isNaN(Number(value))) {
      return null;
    } else {
      return Number(value);
    }
  }

  /**
   * Clear all saved preferences
   */
  async clear(): Promise<void> {
    await Preferences.clear();
  }
}

import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

/** Client Side Image Manipulation */
@Injectable({
  providedIn: "root",
})
export class ImageCompressorService {
  readonly thumbsize = 200;
  readonly maximumImageUploadDimension = 1920;

  /** @ignore */
  constructor(private sanitiser: DomSanitizer) {}

  /**
   * Reduce report image size and quality
   * @param {string} fullpath path of file on device
   * @param {number} quality 0 - 100
   * @returns {string} Base64 Image
   *
   * @deprecated not used in web?
   */
  compress(fullpath: string, quality: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const ratio = Math.round(quality / 2);

        let img: any = new Image();

        img.onerror = (err: any) => {
          reject(err);
        };

        img.onload = () => {
          let oldWidth = img.width;
          let oldHeight = img.height;
          // Create a temporary canvas to draw the downscaled image on.
          let canvas = document.createElement("canvas");
          canvas.width = Math.round(oldWidth * (ratio / 100));
          canvas.height = Math.round(oldHeight * (ratio / 100));
          // Draw the downscaled image on the canvas and return the new data URL.
          let ctx: any = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          let dataUrl = canvas.toDataURL("image/jpeg", quality / 100);
          if (dataUrl === "data:,") {
            reject("Data Url Corrupted: " + dataUrl);
          }
          resolve(dataUrl);
        };

        img.src = this.sanitiser.sanitize(
          SecurityContext.RESOURCE_URL,
          fullpath
          // this.sanitiser.bypassSecurityTrustResourceUrl(
          //   Capacitor.convertFileSrc(fullpath)
          // )
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Change size of image while maintaining aspect ratio
   * - Quality defaults to 0.8
   */
  limitImageSize(dataurl: string, maxDimension: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        let img = new Image();

        img.onerror = (err) => {
          reject(err);
        };

        img.onload = () => {
          const oldWidth = img.width;
          const oldHeight = img.height;
          const maxVal = Math.max(oldWidth, oldHeight);

          let canvas = document.createElement("canvas");

          if (maxVal > maxDimension) {
            let newWidth: number, newHeight: number;

            if (oldWidth === oldHeight) {
              newHeight = maxDimension;
              newWidth = maxDimension;
            } else if (oldHeight > oldWidth) {
              newHeight = maxDimension;
              newWidth = Math.round((maxDimension * oldWidth) / oldHeight);
            } else {
              newHeight = Math.round((maxDimension * oldHeight) / oldWidth);
              newWidth = maxDimension;
            }

            // Create a temporary canvas to draw the downscaled image on.
            canvas.height = newHeight;
            canvas.width = newWidth;
            let ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

            if (img.width === img.height) {
              ctx?.drawImage(img, 0, 0, maxDimension, maxDimension);
            } else {
              ctx?.drawImage(
                img,
                0,
                0,
                oldWidth,
                oldHeight,
                0,
                0,
                newWidth,
                newHeight
              );
            }
          } else {
            // Image is smaller than max size, no change
            canvas.height = oldHeight;
            canvas.width = oldWidth;
            let ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

            ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
          }

          // Draw the downscaled image on the canvas and return the new data URL
          const dataUrl = canvas.toDataURL("image/jpeg", 0.9);
          if (dataUrl === "data:,") {
            reject("Data Url Corrupted: " + dataUrl);
          }
          resolve(dataUrl);
        };

        if (dataurl) {
          img.src = dataurl;
        } else {
          reject("No image path available");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Generate a square cropped thumbnail
   * @param {string} dataUrl
   * @param {number} quality defaults to 0.8
   * @returns {string} Base64 Image
   *
   * @since 1.3.0
   *
   * @deprecated not used in web?
   */
  generateThumbnail(
    dataUrl: string,
    thumbsize: number,
    quality: number
  ): Promise<string> {
    quality = quality || 0.8;
    quality = quality > 1 ? 1 : 0.8;

    return new Promise(async (resolve, reject) => {
      try {
        let img = new Image();

        /**
         * If the image can't be loaded for whatever reason
         * reject out of this function to continue. onload is never
         * called when the image can't load
         */
        img.onerror = (err) => {
          reject(err);
        };

        img.onload = () => {
          const oldWidth = img.width;
          const oldHeight = img.height;

          // Create a temporary canvas to draw the downscaled image on.
          let canvas = document.createElement("canvas");
          canvas.width = thumbsize;
          canvas.height = thumbsize;

          let ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

          if (img.width == img.height) {
            // already square
            ctx?.drawImage(img, 0, 0, thumbsize, thumbsize);
          } else {
            const minVal = Math.min(oldWidth, oldHeight);
            if (oldWidth > oldHeight) {
              ctx?.drawImage(
                img,
                (oldWidth - minVal) / 2,
                0,
                minVal,
                minVal,
                0,
                0,
                thumbsize,
                thumbsize
              );
            } else {
              ctx?.drawImage(
                img,
                0,
                (oldHeight - minVal) / 2,
                minVal,
                minVal,
                0,
                0,
                thumbsize,
                thumbsize
              );
            }
          }

          // Draw the downscaled image on the canvas and return the new data URL
          const dataUrl = canvas.toDataURL("image/jpeg", 0.8);

          resolve(dataUrl);
        };

        img.src = dataUrl;
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Generate a square cropped thumbnail
   * @param {string} dataUrl
   * @returns {string} Base64 Image
   *
   * @since 1.3.0
   */
  generateLogo(dataUrl: string): Promise<string> {
    const quality = 0.8;
    const maxWidth = 600;
    const maxHeight = 400;

    return new Promise(async (resolve, reject) => {
      try {
        let img = new Image();

        /**
         * If the image can't be loaded for whatever reason
         * reject out of this function to continue. onload is never
         * called when the image can't load
         */
        img.onerror = (err) => {
          reject(err);
        };

        img.onload = () => {
          const oldWidth = img.width;
          const oldHeight = img.height;

          // Create a temporary canvas to draw the downscaled image on.
          let canvas = document.createElement("canvas");
          canvas.width = maxWidth;
          canvas.height = maxHeight;

          let ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

          const hRatio = maxWidth / oldWidth;
          const vRatio = maxHeight / oldHeight;

          const ratio = Math.min(hRatio, vRatio);

          const centerShift_x = (maxWidth - oldWidth * ratio) / 2;
          const centerShift_y = (maxHeight - oldHeight * ratio) / 2;

          ctx!.fillStyle = "white";
          ctx?.fillRect(0, 0, maxWidth, maxHeight);
          ctx?.drawImage(
            img,
            0,
            0,
            oldWidth,
            oldHeight,
            centerShift_x,
            centerShift_y,
            oldWidth * ratio,
            oldHeight * ratio
          );

          // Draw the downscaled image on the canvas and return the new data URL
          const newDataUrl = canvas.toDataURL("image/jpeg", quality);
          resolve(newDataUrl);
        };

        img.src = dataUrl;
      } catch (err) {
        reject(err);
      }
    });
  }
}

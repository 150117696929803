import { Injectable } from "@angular/core";
import type {
  IApiResponse,
  Endpoint,
  PrimaryKeyId,
  UploadFileProps,
} from "src/app/models";

import { HttpService } from "../http.service";
import { UserService } from "../user.service";

import { ImageType } from "src/app/models";
import { HTTP_METHOD } from ".";

@Injectable({
  providedIn: "root",
})
export class FilesApiService {
  private _photos = "Photos" as Endpoint;

  constructor(private http: HttpService, private us: UserService) {}

  /**
   * Retieves tenant information
   * @returns {Promise<IApiResponse<T>>} varying response based on imageType
   *
   * - Project Response: `{ cloudId: PrimaryKeyId; filename: string; };`
   * - Logo Response: `{ logoId: PrimaryKeyId; url: string };`
   */
  async uploadImage<T>(
    files: File[],
    props: UploadFileProps
  ): Promise<IApiResponse<T>> {
    const ids = await this.us.checkAndGetUserIds();

    const fields = {
      tenantId: ids.tenantId,
      userId: ids.userId,
      ...props,
    };

    return this.http.uploadFiles<T>(this._photos, fields, files);
  }

  /**
   * Delete an image.
   *
   * @param {PrimaryKeyId} photoId - The ID of the photo to delete.
   * @param {ImageType} imageType - The type of the image to delete.
   * @return {Promise<IApiResponse>} A promise that resolves with the API response.
   */
  async deleteImage(
    photoId: PrimaryKeyId,
    imageType: ImageType
  ): Promise<IApiResponse> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IApiResponse>(
      HTTP_METHOD.DELETE,
      this._photos,
      null,
      { tenantId, photoId, imageType },
      false
    );
  }

  /**
   * Converts a base64 url to a File for upload
   * @param dataurl
   * @param filename
   * @param mimeType
   * @returns
   */
  dataURLtoFile(dataurl: string, filename: string, mimeType?: string): File {
    const arr = dataurl.split(",");
    // var mime = arr[0].match(/:(.*?);/)[1];
    const mime = mimeType || "image/jpeg";
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /**
   * Converts a URL blob to a File object.
   *
   * @param {string} blob - The URL of the blob.
   * @param {string} filename - The desired filename of the resulting File object.
   * @param {string} mimeType - (optional) The MIME type of the blob.
   * @return {Promise<File>} A Promise that resolves with the converted File object.
   */
  async blobToFile(
    blob: string,
    filename: string,
    mimeType?: string
  ): Promise<File> {
    if (blob.startsWith("data:")) {
      const arr = blob.split(",");
      const mime = mimeType || "image/jpeg";
      // var mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[arr.length - 1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const file = new File([u8arr], filename, { type: mime });
      return Promise.resolve(file);
    }
    return fetch(blob)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }
}

import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";

import type {
  IApiResponse,
  Endpoint,
  IHttpDefect,
  IHttpEditDefect,
  ProjectPrimaryKeyId,
  DefectPrimaryKeyId,
  ListPrimaryKeyId,
  PrimaryKeyId,
  IHttpGetSite,
  IHttpGetClientProject,
} from "../../models";

import {
  ICreateDefect,
  IEditDefect,
  IEditDefectContractor,
  IEditDefectPhoto,
  IHttpCreateDefect,
  ICreateDefectPhoto,
  PERMISSION_CHECK_TYPE,
} from "../../models";

import { UserService } from "../user.service";
import { HTTP_METHOD } from ".";

@Injectable({
  providedIn: "root",
})
export class ClientApiService {
  private _clientRegister = "ClientRegister" as Endpoint;
  private _getClientProject = "ClientProject" as Endpoint;
  private _getSite = "GetSite" as Endpoint;
  private _getDefect = "GetDefect" as Endpoint;
  private _createDefect = "CreateDefect" as Endpoint;
  private _editDefect = "EditDefect" as Endpoint;
  private _client = "Client" as Endpoint;

  constructor(private http: HttpService, private us: UserService) {}

  /**
   * Creates a client user with the given email, first name, last name, and Firebase ID.
   *
   * @param {string} firstname - The first name of the user.
   * @param {string} lastname - The last name of the user.
   * @return {Promise<IApiResponse<undefined>>} A promise that resolves to the API response
   */
  async createClientUser(
    firstname: string,
    lastname: string,
    phone: string,
    role: string | "manager" | "owner"
  ): Promise<IApiResponse<undefined>> {
    return this.http.request<undefined>(
      HTTP_METHOD.POST,
      this._clientRegister,
      null,
      {
        firstname,
        lastname,
        phone,
        role,
      }
    );
  }

  /**
   * Gets the details of a project
   * @param {ProjectPrimaryKeyId} projectId cloud id of project
   * @returns {Promise<IApiResponse<IHttpGetClientProject>>} Project data
   */
  async getProject(
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpGetClientProject>> {
    const clientIds = await this.us.checkAndGetClientIds();
    return this.http.request<IHttpGetClientProject>(
      HTTP_METHOD.GET,
      this._getClientProject,
      { projectId, clientId: clientIds.userId },
      null,
      false,
      PERMISSION_CHECK_TYPE.CLIENT
    );
  }

  /**
   * Returns the site details and defects for a site
   * @param listId Cloud Id of site to get
   * @param projectCloudId
   * @returns {Promise<IApiResponse<IHttpGetSite>>}
   */
  async getList(
    listId: ListPrimaryKeyId,
    projectCloudId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpGetSite>> {
    const clientIds = await this.us.checkAndGetClientIds();
    return this.http.request<IHttpGetSite>(
      HTTP_METHOD.GET,
      this._getSite,
      {
        tenantId: clientIds.tenantId,
        userId: clientIds.userId,
        siteId: listId,
        projectId: projectCloudId,
      },
      null,
      false,
      PERMISSION_CHECK_TYPE.CLIENT
    );
  }

  /**
   * Gets the details of an individual defect
   * @param {DefectPrimaryKeyId} defectId Cloud Id of the defect
   * @param {ProjectPrimaryKeyId} projectId Cloud Id of the project
   * @returns
   */
  async getDefect(
    defectId: DefectPrimaryKeyId,
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpDefect>> {
    const clientIds = await this.us.checkAndGetClientIds();
    return this.http.request<IHttpDefect>(
      HTTP_METHOD.GET,
      this._getDefect,
      { tenantId: clientIds.tenantId, defectId, projectId },
      null,
      false,
      PERMISSION_CHECK_TYPE.CLIENT
    );
  }

  /**
   * Creates a new defect
   * @param {ICreateDefect} defect Data for new defect
   * @param {number[]} contractorIds ContractorId's to add to the defect
   * @param {IPhoto[]} photos
   * @param {number} projectId Cloud Id of the project
   * @returns {Promise<IApiResponse<IApiUpdateResponse>>} New cloud Id and lastmodified
   */
  async createDefect(
    defect: ICreateDefect,
    contractorIds: PrimaryKeyId[],
    photos: ICreateDefectPhoto[],
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpCreateDefect>> {
    const clientIds = await this.us.checkAndGetClientIds();
    return this.http.request<IHttpCreateDefect>(
      HTTP_METHOD.POST,
      this._createDefect,
      null,
      {
        tenantId: clientIds.tenantId,
        userId: clientIds.userId,
        defect,
        contractorIds,
        photos,
        projectId,
      }
    );
  }

  /**
   * Update an existing defect
   * @param {IEditDefect} defect Defect data to update
   * @param {IEditDefectContractor[]} contractors Contractors to add or remove from the defect
   * @param {IEditDefectPhoto[]} photos
   * @param {number} projectId Cloud Id of the project
   * @returns {Promise<IApiResponse<IApiUpdateResponse>>} CloudId and lastmodified
   */
  async editDefect(
    defect: IEditDefect,
    contractors: IEditDefectContractor[],
    photos: IEditDefectPhoto[],
    projectId: ProjectPrimaryKeyId
  ): Promise<IApiResponse<IHttpEditDefect>> {
    const clientIds = await this.us.checkAndGetClientIds();
    return this.http.request<IHttpEditDefect>(
      HTTP_METHOD.PUT,
      this._editDefect,
      null,
      { tenantId: clientIds.tenantId, defect, contractors, photos, projectId }
    );
  }

  async getAccount(): Promise<IApiResponse<_GetClientAccount>> {
    const clientIds = await this.us.checkAndGetClientIds();
    return this.http.request<_GetClientAccount>(
      HTTP_METHOD.GET,
      this._client,
      { userId: clientIds.userId },
      null
    );
  }

  async editAccount(
    firstname: string,
    lastname: string,
    phone: string,
    role: string | "manager" | "owner"
  ): Promise<IApiResponse<IHttpEditDefect>> {
    const clientIds = await this.us.checkAndGetClientIds();
    return this.http.request<IHttpEditDefect>(
      HTTP_METHOD.PUT,
      this._client,
      null,
      { userId: clientIds.userId, firstname, lastname, phone, role }
    );
  }
}

type _GetClientAccount = {
  readonly firstname: string;
  readonly lastname: string;
  readonly phone: string;
  readonly role: "" | "owner" | "manager";
};

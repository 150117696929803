import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { UserService } from "../user.service";

import type { IApiResponse, IHttpApiUpdateResponse } from "../../models";
import { Endpoint, PrimaryKeyId } from "../../models";

import {
  ICreateContractor,
  IEditContractor,
  IHttpContractor,
} from "../../models/http";
import { HTTP_METHOD } from ".";

/** Simple AlertController & ToastController Implementation */
@Injectable({
  providedIn: "root",
})
export class ContractorApiService {
  private _getContractors = "GetContractors" as Endpoint;
  private _createContractor = "CreateContractor" as Endpoint;
  private _editContractor = "EditContractor" as Endpoint;
  private _deleteContractor = "DeleteContractor" as Endpoint;

  /** @ignore */
  constructor(private http: HttpService, private us: UserService) {}

  /**
   * Gets all contractors of the tenant
   * @returns {Promise<IApiResponse<IHttpContractor[]>>} Array of tenant contractors
   */
  async getContractors(): Promise<IApiResponse<IHttpContractor[]>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IHttpContractor[]>(
      HTTP_METHOD.GET,
      this._getContractors,
      { tenantId },
      null
    );
  }

  /**
   * Creates a new contractor in the database
   * @param {ICreateContractor} contractor New contractor data
   * @returns {Promise<IApiResponse<IHttpApiUpdateResponse>>} Cloud Id and lastmodified
   */
  async createContractor(
    contractor: ICreateContractor
  ): Promise<IApiResponse<IHttpApiUpdateResponse>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IHttpApiUpdateResponse>(
      HTTP_METHOD.POST,
      this._createContractor,
      null,
      { tenantId, contractor }
    );
  }

  /**
   * Updates the details of an existing contractor
   * @param {IEditContractor} contractor Details to update
   * @returns {Promise<IApiResponse<IHttpApiUpdateResponse>>} CloudId and lastmodified
   */
  async editContractor(
    contractor: IEditContractor
  ): Promise<IApiResponse<IHttpApiUpdateResponse>> {
    return this.http.request<IHttpApiUpdateResponse>(
      HTTP_METHOD.PUT,
      this._editContractor,
      null,
      {
        contractor: {
          ...contractor,
          deleted: contractor.deleted === 1,
        },
      }
    );
  }

  /**
   * Deletes a contractor from the database
   * @param {PrimaryKeyId} contractorId cloud id of contractor
   * @returns {Promise<IApiResponse>} Success and message
   */
  async deleteContractor(contractorId: PrimaryKeyId): Promise<IApiResponse> {
    return this.http.request(HTTP_METHOD.DELETE, this._deleteContractor, null, {
      contractorId,
    });
  }
}

import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { UserService } from "../user.service";

import type { Endpoint, IApiResponse } from "../../models";

import { PrimaryKeyId, DefectPrimaryKeyId } from "../../models";

import { HTTP_METHOD } from ".";

@Injectable({
  providedIn: "root",
})
export class CommentsApiService {
  private _comments = "Comments" as Endpoint;

  /** @ignore */
  constructor(private http: HttpService, private us: UserService) {}

  /**
   * Retrieves the comment data associated with a given defect ID.
   *
   * @param {DefectPrimaryKeyId} defectId - The ID of the defect.
   * @return {Promise<IApiResponse<_CommentGetData[]>>} A promise that resolves to an array of comment data.
   */
  async get(
    defectId: DefectPrimaryKeyId
  ): Promise<IApiResponse<CommentGetData[]>> {
    return this.http.request<CommentGetData[]>(
      HTTP_METHOD.GET,
      this._comments,
      { defectId: defectId },
      null,
      true
    );
  }

  /**
   * Creates a comment with the provided text, photos, and defect ID.
   *
   * @param {string} text - The text content of the comment.
   * @param {CommentPhotoCreate[]} photos - An array of comment photos.
   * @param {DefectPrimaryKeyId} defectId - The ID of the defect associated with the comment.
   * @return {Promise<IApiResponse<_CommentCreateData>>} A promise that resolves to the created comment data.
   */
  async create(
    text: string,
    photos: CommentPhotoCreate[],
    defectId: DefectPrimaryKeyId
  ): Promise<IApiResponse<_CommentCreateData>> {
    const ids = await this.us.checkAndGetUserIds();

    return this.http.request<_CommentCreateData>(
      HTTP_METHOD.POST,
      this._comments,
      null,
      {
        tenantId: ids.tenantId,
        userId: ids.userId,
        defectId: defectId,
        comment: text,
        photos: photos,
      },
      false
    );
  }

  /**
   * Updates a comment with new text and photo maps.
   *
   * @param {PrimaryKeyId} commentId - The ID of the comment to be updated.
   * @param {string} newText - The new text for the comment.
   * @param {_CommentPhotoUpdate[]} photoMaps - An array of photo maps for the comment.
   * @return {Promise<IApiResponse<_CommentUpdateData>>} A promise that resolves to the API response containing the updated comment data.
   */
  async update(
    commentId: PrimaryKeyId,
    newText: string,
    photoMaps: CommentPhotoUpdate[]
  ): Promise<IApiResponse<_CommentUpdateData>> {
    const ids = await this.us.checkAndGetUserIds();

    return this.http.request<_CommentUpdateData>(
      HTTP_METHOD.PUT,
      this._comments,
      null,
      {
        userId: ids.userId,
        commentId: commentId,
        comment: newText,
        photos: photoMaps,
      },
      false
    );
  }

  /**
   * Deletes a comment.
   *
   * @param {PrimaryKeyId} commentId - The ID of the comment to delete.
   * @return {Promise<IApiResponse<_CommentDeleteData>>} A promise that resolves with the API response containing the deleted comment data.
   */
  async delete(commentId: PrimaryKeyId): Promise<IApiResponse<undefined>> {
    return this.http.request<undefined>(
      HTTP_METHOD.DELETE,
      this._comments,
      null,
      { commentId },
      false
    );
  }
}

/** Required to create a comments photo */
export type CommentPhotoCreate = {
  filename: string | "";
  base64: string;
};

/** Required to update a comments photo */
export type CommentPhotoUpdate = {
  cloudId?: PrimaryKeyId;
  filename: string | "";
  base64: string | "";
  action: "add" | "remove";
};

export type CommentGetData = {
  cloudId: PrimaryKeyId;
  comment: string;
  userId: PrimaryKeyId;
  username: string;
  created: number;
  lastModified: number;
  photos: { cloudId: PrimaryKeyId; fullPath: string; thumbnail: string }[];
};

type _CommentCreateData = {
  cloudId: PrimaryKeyId;
  modified: number;
  photos: {
    /** filename not used on web */
    filename: string;
    cloudId: number;
    fullPath: string;
    thumbnail: string;
  }[];
};

type _CommentUpdateData = {
  cloudId: PrimaryKeyId;
  modified: number;
  /** newly added photo information only */
  photos: {
    cloudId: PrimaryKeyId;
    fullPath: string;
    thumbnail: string;
    filename: string;
  }[];
};

import { Injectable } from "@angular/core";
import type { IApiResponse, Endpoint, PrimaryKeyId } from "src/app/models";
import { HTTP_METHOD } from ".";
import { HttpService } from "../http.service";
import { UserService } from "../user.service";

@Injectable({
  providedIn: "root",
})
export class TenantApiService {
  private _getTenant = "GetTenant" as Endpoint;
  private _editTenant = "EditTenant" as Endpoint;
  private _deleteTenantUser = "DeleteTenantUser" as Endpoint;
  // private _deleteTenant = "DeleteTenant"as Endpoint;
  private _tenantLogo = "TenantLogo" as Endpoint;

  constructor(private http: HttpService, private us: UserService) {}

  /**
   * Retieves tenant information
   * @returns {Promise<IApiResponse<{ comapny: string }>>}
   */
  async getTenant(): Promise<
    IApiResponse<{
      readonly company: string;
      readonly timezone: string;
      readonly logo: { logoId: PrimaryKeyId; url: string };
    }>
  > {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<{
      company: string;
      timezone: string;
      logo: { logoId: PrimaryKeyId; url: string };
    }>(HTTP_METHOD.GET, this._getTenant, { tenantId }, null);
  }

  /**
   * Updates tenant information
   * @param {string} company
   * @returns {Promise<IApiResponse>}
   */
  async editTenant(company: string, timezone: string): Promise<IApiResponse> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<IApiResponse>(
      HTTP_METHOD.PUT,
      this._editTenant,
      null,
      { tenantId, company, timezone }
    );
  }

  /**
   *
   * @param userId
   * @returns
   */
  async deleteTenantUser(userId: PrimaryKeyId): Promise<IApiResponse> {
    return this.http.request(HTTP_METHOD.DELETE, this._deleteTenantUser, null, {
      userId,
    });
  }

  /**
   * @param logoId Cloud Doc Id of the logo (returned from /GetTenant currently)
   * @returns
   * @since 1.5.0
   */
  async deleteTenantLogo(logoId: number): Promise<IApiResponse> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request(HTTP_METHOD.DELETE, this._tenantLogo, null, {
      tenantId: tenantId,
      logoId: logoId,
    });
  }

  /**
   *
   * @returns
   */
  async deleteTenant(): Promise<IApiResponse> {
    const ids = await this.us.checkAndGetUserIds();
    return this.http.request(HTTP_METHOD.DELETE, this._deleteTenantUser, null, {
      userId: ids.userId,
      tenantId: ids.tenantId,
    });
  }
}

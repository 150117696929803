import { Injectable } from "@angular/core";
import type {
  IApiResponse,
  Endpoint,
  IUserInvite,
  ITenantInvite,
  PrimaryKeyId,
} from "src/app/models";
import { ISendInvite, IUserInviteStatus } from "src/app/models";
import { HTTP_METHOD } from ".";
import { HttpService } from "../http.service";
import { UserService } from "../user.service";

@Injectable({
  providedIn: "root",
})
export class InviteApiService {
  private _getInvites = "GetInvites" as Endpoint;
  private _getInvite = "GetInvite" as Endpoint;
  private _editInvite = "EditInvite" as Endpoint;
  private _sendInvites = "SendInvites" as Endpoint;
  private _deleteInvite = "DeleteInvite" as Endpoint;

  constructor(private http: HttpService, private us: UserService) {}

  /**
   * Retrieves all invites
   * @returns {Promise<IApiResponse<ITenantInvite[]>>}
   */
  async getInvites(): Promise<IApiResponse<ITenantInvite[]>> {
    const tenantId = await this.us.checkAndGetTenantId();
    return this.http.request<ITenantInvite[]>(
      HTTP_METHOD.GET,
      this._getInvites,
      { tenantId },
      null
    );
  }

  /**
   * Retrieves a singular invite
   * @param {string} email
   * @returns {Promise<IApiResponse<IUserInvite>>}
   */
  async getInvite(email: string): Promise<IApiResponse<IUserInvite>> {
    return this.http.request<IUserInvite>(
      HTTP_METHOD.GET,
      this._getInvite,
      { email },
      null
    );
  }

  /**
   * Edits a singular invite
   * @param {PrimaryKeyId} inviteId
   * @param {string} status
   * @param {boolean} transferProjects when status is `Accepted`, option to transfer cloud data.
   * @returns {Promise<IApiResponse>}
   */
  async editInvite(
    inviteId: PrimaryKeyId,
    status: IUserInviteStatus,
    transferProjects?: boolean
  ): Promise<IApiResponse> {
    return this.http.request(HTTP_METHOD.PUT, this._editInvite, null, {
      id: inviteId,
      status,
      transferProjects,
    });
  }

  /**
   * Sends invites to specified email addresses
   * @param {ISendInvite[]} invites
   * @returns {Promise<IApiResponse<>>}
   */
  async sendInvites(
    invites: ISendInvite[]
  ): Promise<IApiResponse<_SendInviteResponse[]>> {
    const ids = await this.us.checkAndGetUserIds();
    return this.http.request(HTTP_METHOD.POST, this._sendInvites, null, {
      tenantId: ids.tenantId,
      invites,
      userId: ids.userId,
    });
  }

  /**
   * Deletes an invite completely from the database
   * @param {PrimaryKeyId} inviteId Database Id of invite to delete
   * @returns {Promise<IApiResponse>}
   */
  async deleteInvite(inviteId: PrimaryKeyId): Promise<IApiResponse> {
    return this.http.request(HTTP_METHOD.DELETE, this._deleteInvite, null, {
      inviteId,
    });
  }
}

interface _SendInviteResponse {
  cloudId: PrimaryKeyId;
  emailAddress: string;
  inviteStatus: IUserInviteStatus;
  lastModified: string;
  localId: null;
}

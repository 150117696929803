import { Injectable } from "@angular/core";

import type {
  IHttpGetClientProject,
  IHttpGetProject,
  ListPrimaryKeyId,
  PrimaryKeyId,
  ProjectPrimaryKeyId,
} from "../models";

import {
  Project,
  List,
  ICreateProject,
  ICreateSite,
  IApiResponseData,
  ProjectPermission,
  TenantRole,
} from "../models";

import { UserService } from "./user.service";
import { ApiService } from "./api/index";

/** Project management service */
@Injectable({
  providedIn: "root",
})
export class ProjectService {
  /** @ignore */
  constructor(private api: ApiService, private us: UserService) {}

  /**
   * Get Project Page data
   * @param projectId projectId or cloudId
   * @param cloudproject whether to search device for project
   * @returns
   *
   * @since 1.3.0
   */
  async getProjectPageData(projectId: ProjectPrimaryKeyId): Promise<{
    project: Project.Project;
    lists: Project.ListView[];
    contractors: Project.Contractor[];
    projectTeam: Project.TeamMember[];
    reports: Project.Report[];
    isOwner: boolean;
  }> {
    // console.log("getProjectPageData");

    let project: Project.Project,
      lists: Project.ListView[] = [],
      contractors: Project.Contractor[] = [],
      projectTeam: Project.TeamMember[] = [],
      reports: Project.Report[] = [],
      isOwner: boolean = false;

    project = {
      id: projectId,
      type: 0,
      name: "",
      reference: "",
      client: "",
      address: "",
      city: "",
      postcode: "",
      state: "",
      country: "",
      created: "",
      lastModified: 0,
      role: TenantRole.owner,
      photo: { url: "", photoId: -1 as PrimaryKeyId },
      clientEnabled: false,
    };

    try {
      // Augment with cloud data if able
      let cloudProject: IApiResponseData<IHttpGetProject>;
      try {
        cloudProject = await this.api.projects.getProject(project.id);
        if (cloudProject.success) {
          let cloudResult = cloudProject.data;

          project = {
            ...cloudProject.data.project,
            id: cloudProject.data.project.id,
            created: cloudProject.data.project.createDate,
            lastModified: cloudProject.data.project.lastModified,
          };

          lists = cloudResult.sites.map((cs) => {
            return {
              cloudId: cs.id,
              unit: cs.description || "",
              address: cs.address || "",
              floor: cs.floor || "",
              defectsCount: cs.defectsCount,
              newCount: cs.newCount,
              inProgressCount: cs.inProgressCount,
              completeCount: cs.completeCount,
              overdueCount: cs.overdueCount,
              lastModified: cs.lastModified,
              lastModifiedDefect: cs.lastModifiedDefect,
              isSynced: 0,
            };
          });

          contractors = cloudProject.data.contractors.map<Project.Contractor>(
            (o: any) => {
              return {
                projectMapId: null,
                contractorCloudId: o.id,
                company: o.company,
                name: o.name,
                defaultAssignment: o.defaultAssignment === 1,
              };
            }
          );
          projectTeam = cloudResult.projectTeam;
          reports = cloudResult.projectReports;
        }
      } catch (ex) {
        return Promise.reject(ex);
      }

      const userId = await this.us.checkAndGetUserId();
      const currIndex = projectTeam.findIndex((o) => o.userId === userId);
      isOwner = projectTeam[currIndex].permission === ProjectPermission.owner;
    } catch (ex) {
      //
    }

    return Promise.resolve({
      project: project,
      lists,
      contractors: contractors,
      projectTeam,
      reports,
      isOwner,
    });
  }

  /**
   * Get Project Page data
   * @param projectId projectId or cloudId
   * @param cloudproject whether to search device for project
   * @returns
   *
   * @since 1.3.0
   */
  async getClientProjectPageData(projectId: ProjectPrimaryKeyId): Promise<{
    project: Project.Project;
    lists: Project.ListView[];
    contractors: Project.Contractor[];
    company: string;
    projectImage: string;
  }> {
    // console.log("getProjectPageData");

    let project: Project.Project,
      lists: Project.ListView[] = [],
      contractors: Project.Contractor[] = [],
      projectImage = "",
      company = "";

    project = {
      id: projectId,
      type: 0,
      name: "",
      reference: "",
      client: "",
      address: "",
      city: "",
      postcode: "",
      state: "",
      country: "",
      created: "",
      lastModified: 0,
      role: TenantRole.owner,
      photo: { url: "", photoId: -1 as PrimaryKeyId },
      clientEnabled: false,
    };

    try {
      // Augment with cloud data if able
      let cloudProject: IApiResponseData<IHttpGetClientProject>;
      try {
        cloudProject = await this.api.client.getProject(project.id);
        if (cloudProject.success) {
          let cloudResult = cloudProject.data;

          company = cloudProject.data.project.company;
          projectImage = cloudProject.data.project.photo.url;

          project = {
            ...cloudProject.data.project,
            id: cloudProject.data.project.id,
            created: cloudProject.data.project.createDate,
            lastModified: cloudProject.data.project.lastModified,
          };

          lists = cloudResult.lists.map((cs) => {
            return {
              cloudId: cs.id,
              unit: cs.description || "",
              address: cs.address || "",
              floor: cs.floor || "",
              defectsCount: cs.defectsCount,
              newCount: cs.newCount,
              inProgressCount: cs.inProgressCount,
              completeCount: cs.completeCount,
              overdueCount: cs.overdueCount,
              lastModified: cs.lastModified,
              lastModifiedDefect: cs.lastModifiedDefect,
              isSynced: 0,
            };
          });

          // contractors = cloudProject.data.contractors.map<Project.Contractor>(
          //   (o: any) => {
          //     return {
          //       projectMapId: null,
          //       contractorCloudId: o.id,
          //       company: o.company,
          //       name: o.name,
          //       defaultAssignment: o.defaultAssignment === 1,
          //     };
          //   }
          // );
        }
      } catch (ex) {
        return Promise.reject(ex);
      }
    } catch (ex) {
      //
    }

    return Promise.resolve({
      project: project,
      lists,
      contractors: contractors,
      company,
      projectImage,
    });
  }

  /**
   * Create a new project on the server
   * - Do this AFTER creating locally so as to provide Site.Id's
   * @param {Project.Project} project
   * @param {IListView[]} sites Site.Id required if saved locally
   */
  async createCloudProject(
    project: Project.Project,
    sites: List.List[]
  ): Promise<{ success: boolean; cloudId: ProjectPrimaryKeyId | null }> {
    if (this.us.premium === undefined) {
      await this.us.awaitPremium();
    }

    if (this.us.premium) {
      const tenantId = await this.us.checkAndGetTenantId();

      const cloudProject: ICreateProject = {
        tenantId: tenantId,
        project: {
          name: project.name,
          ref: project.reference,
          address: project.address,
          city: project.city,
          postcode: project.postcode,
          state: project.state,
          country: project.country,
          client: project.client,
          status: 1,
          type: project.type,
        },
        sites: sites.map<ICreateSite>((o: List.List) => {
          let id: ListPrimaryKeyId | undefined = undefined;
          try {
            assertListPrimaryKeyId(o.cloudId);
            id = o.cloudId;
          } catch (ex) {
            //
          }
          return {
            id,
            projectId: project.id,
            unit: o.unit,
            floor: o.floor,
            address: o.address,
            notes: o.notes ?? "",
          };
        }),
      };

      const httpResult = await this.api.projects.createProject(cloudProject);

      return {
        success: httpResult.success,
        cloudId:
          (httpResult.data?.project.cloudId as ProjectPrimaryKeyId) || null,
      };
    } else {
      return { success: false, cloudId: null };
    }
  }

  /**
   * Update server project
   * - Updates last mod date if project is on device
   *
   * @param {Project.Project} project
   */
  async editCloudProject(
    project: Project.Project
  ): Promise<{ success: boolean; message: string }> {
    if (this.us.premium === undefined) {
      await this.us.awaitPremium();
    }

    if (this.us.premium) {
      const httpResult = await this.api.projects.editProject({
        name: project.name,
        ref: project.reference,
        address: project.address,
        city: project.city,
        postcode: project.postcode,
        state: project.state,
        country: project.country,
        client: project.client,
        type: project.type,
        id: project.id,
        status: 1,
        clientEnabled: project.clientEnabled,
      });

      return Promise.resolve({
        success: httpResult.success,
        message: httpResult.message,
      });
    } else {
      return Promise.resolve({
        success: true,
        message: "Offline or free account",
      });
    }
  }
}

function assertListPrimaryKeyId(
  value: number
): asserts value is ListPrimaryKeyId {
  if (value < 0) throw new Error("Invalid id");
}

import { Injectable } from "@angular/core";
import { ClientApiService } from "./client-api.service";
import { CommentsApiService } from "./comments-api.service";
import { ContractorApiService } from "./contractor-api.service";
import { DefectApiService } from "./defect-api.service";
import { InviteApiService } from "./invite-api.service";
import { FilesApiService } from "./files-api.service";
import { ProjectApiService } from "./project-api.service";
import { ListApiService } from "./site-api.service";
import { UserApiService } from "./user-api.service";
import { TenantApiService } from "./tenant-api.service";
import { ReportsApiService } from "./reports-api.service";
import { BillingApiService } from "./billing-api-service.service";

export enum HTTP_METHOD {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

@Injectable({
  providedIn: "root",
})
export class ApiService {
  /** @ignore */
  constructor(
    // APIS
    public billing: BillingApiService,
    public client: ClientApiService,
    public comments: CommentsApiService,
    public contractor: ContractorApiService,
    public defects: DefectApiService,
    public invite: InviteApiService,
    public files: FilesApiService,
    public projects: ProjectApiService,
    public sites: ListApiService,
    public user: UserApiService,
    public tenant: TenantApiService,
    public reports: ReportsApiService
  ) {}
}
